export default {
  proxy_path: "https://tu9ucbla06.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://58ia6cnu47.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyCvHe11XoXXIdcqwKIRI9kivWPjAvNdaAQ",
  main_company_term_id: "6",
  crm_base_url: "https://crm.newmont.forwoodsafety.com",
  forwood_id_url: "https://id.newmont.forwoodsafety.com?redirect_uri=https://mapreport.newmont.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.newmont.forwoodsafety.com",
  Auth: {
    userPoolId: "us-west-2_wGCB0IPfa",
    userPoolWebClientId: "16h68ts2jqsaqt1d30op1jum83",
    cookieStorage: {
      domain: ".newmont.forwoodsafety.com",
      secure: true
    }
  }
};
